*,
*::before,
*::after {
  box-sizing: border-box;
}
@font-face {
  font-family: "Prompt";
  src:
    url("../fonts/Prompt/Prompt-Regular.woff2") format("woff2"),
    url("../fonts/Prompt/Prompt-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  overflow-x: clip;
  @media (max-width: 690px) {
    overflow-x: unset;
  }
  font-family: "Prompt", sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  letter-spacing: 0;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(247, 247, 247, 0.9);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: var(--gray-color);
    //--gray-color
  }
  h1,
  h2,
  h3,
  p {
    margin: 0;
  }
  a {
    outline: none;
    text-decoration: none;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.--1 {
    -webkit-line-clamp: 1;
  }
  &.--2 {
    -webkit-line-clamp: 2;
  }
  &.--3 {
    -webkit-line-clamp: 3;
  }
  &.--4 {
    -webkit-line-clamp: 4;
  }
  &.--5 {
    -webkit-line-clamp: 5;
  }
  &.--6 {
    -webkit-line-clamp: 6;
  }
}

.container {
  max-width: 1300px !important;
  width: 100%;
  margin: auto;
  padding: 0 15px;
}

.background-default {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  height: 99vh;
  max-width: 99vw;
  max-height: 99vh;
}

/* --------------------------- START : LANDING_PAGE --------------------------- */
.show-landing-page {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.svgll {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  height: 99vh;
  max-width: 99vw;
  max-height: 99vh;
}

.LazyLoad {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.is-visible {
  opacity: 1;
}
.show-mobile {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}

.show-desktop {
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
}
.position-sticky-left-content {
  position: sticky;
  top: 97px;
  z-index: 14;
  @media (max-width: 768px) {
    position: sticky;
    top: 48px;
    left: 0;
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100% !important;
    min-height: 40px;
  }
}

.position-sticky-left-content-js {
  position: sticky;
  top: 97px;
  z-index: 14;
  @media (max-width: 768px) {
    position: absolute;
    right: 0%;
    transform: translate(0, 0);
  }
}

.back-drop {
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0.15) 0px 2px 21.38px,
    rgba(0, 0, 0, 0.19) 0px 32px 64px;
  width: max-content;
  padding: 5px 10px;
  border-radius: 5px;
}
/* --------------------------- ACCESORIES : CARD --------------------------- */

.readmore {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  small {
    border-left: 3px solid var(--tertiary-color);
    padding: 0 10px;
    font-weight: 400;
    font-size: 12px;
    color: var(--black-color);
  }
}

.published-date {
  display: flex;
  align-items: center;
  gap: 5px;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 400;
  small,
  svg {
    color: #424242;
    opacity: 0.95;
  }
}

.count-view {
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 400;
  gap: 5px;
  &::before {
    content: "/";
    color: #424242;
    opacity: 0.95;
  }
  small,
  svg {
    color: #424242;
    opacity: 0.95;
  }
}

.blurb {
  margin: 5px 0;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 1.65;
  font-weight: 500;

  color: var(--black-color);
  opacity: 0.75;

  @media (max-width: 767px) {
    margin: 2.5px 0;
  }
}

.widget-fb {
  display: flex;
  justify-content: center;
  margin: 40px auto 0;
}

.row {
  display: flex;
  --bs-gutter-x: 1.5rem;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.2);
  margin-left: calc(var(--bs-gutter-x) * -0.2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-12 {
  padding: 0;
  flex: 0 0 auto;
  width: 100%;
}

.position-relative {
  position: relative;
}
