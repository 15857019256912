:root {
  --primary-color: #dd5521;
  --secondary-color: #c07745;
  --tertiary-color: #ff8500;
  --quaternary-color: #de5521;
  --background-color: #f8f8f8;
  --text-color: #fff;
  --shadow-color: rgba(242, 122, 53, 0.3);
  --text-hover: #ff8500;
  --white-color: #fff;
  --black-color: #000;
  --gray-color: #454545;
  --border-color: #dddddd;
  --lottery: #4a240a;
  --primary-gradient: linear-gradient(
      278.21deg,
      rgba(255, 255, 255, 0.1) -141.86%,
      #f8eb30 -24.95%,
      rgba(255, 244, 233, 0) 87.38%
    ),
    #b34c26;
  --secondary-gradient: linear-gradient(
    90deg,
    #b05517 0%,
    #e97c00 60%,
    #d77007 63%,
    #c5630e 68%,
    #b95b13 74%,
    #b25616 82%,
    #b05517 100%
  );
  --gray-gradient: linear-gradient(
    90deg,
    #6d6d6d 0%,
    #848484 60%,
    #8c8c8c 63%,
    #828282 68%,
    #737373 74%,
    #666666 82%,
    #6d6d6d 100%
  );
}
